import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Sidebar from './Sidebar';
import CategoriesFooter from '../../components/CategoriesFooter';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';

import Navbar2 from '../../components/Navbar2/Navbar2';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';

const ServiceSinglePage = (props) => {
    const { slug , id } = useParams()
    const { apiSettings } = useAppContext();
    const { i18n } = useTranslation();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const info = apiSettings.settings;
    const lang = i18n.language;
    const catServices = info.catServices;
    const cateroryService = catServices.find(item => item.slug === slug);
    const servicesInCat = cateroryService.services_active;
    const service = servicesInCat.find(item => parseInt(item.id) == id);

   
    return (
        <Fragment>
            <Navbar2/>
            
            <PageTitle pageTitle={service.name[lang]} pagesub={cateroryService.name[lang]} link={`/cat-service/${slug}`} image_path={cateroryService.image_path}  />

            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-8 md:col-span-12 order-1">
                            {/* <img className="w-full max-h-[400px] object-cover" src={service.image_path} alt="" /> */}
                            <div className="pt-[50px]">
                                <h2 className="text-[36px] text-[#282e3f] mb-[25px]">{service.name[lang]} </h2>
                                <h5 className="text-[22px] font-base-font leading-[40px] text-[#282e3f] mb-[25px]">
                               
                                </h5>
                                <p className="text-[16px] leading-[30px] py-[20px] mb-[16px]">
                                    <span dangerouslySetInnerHTML={{ __html: service.description[lang]}}></span>  
                                 </p>
                            </div>
                            <div className="mt-[40px]">
                        
                                <div className="organigation-text  col:float-none col:w-full">
                                    <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">{cateroryService.name[lang]}</h2>
                                   
                                        <div className="flex items-center pt-[15px] text-[15px] text-[#333]">
                                            <i className="fa fa-check-square-o text-[#C0B596] pr-[15px]" aria-hidden="true"></i>
                                            <span className="w-2/3">{service.name[lang]}</span>
                                            <span className="w-1/3 ml-3 ">{service.duration} min</span>
                                            <span className="w-1/3">{service.price_formated} / {service.unit.name[lang]} </span>
                                        </div>
                               
                                </div>
                            </div>
                        </div>
                        <Sidebar lang={lang} slug={slug} id={id} catServices={catServices}/>
                    </div>
                
                </div>
            </div>
         
            <ConsultingArea />
            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <CategoriesFooter lang={lang} catServices={catServices} />
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ServiceSinglePage;

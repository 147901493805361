import React, { useState } from 'react';
import { useForm } from '../../api/formUtils';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
/* import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'; */
// contact form for consulting area
const ConsultingFrom = (props) => {
    const { t } = useTranslation();
    const catServices = props.catServices;
    const lang = props.lang;
    const { forms, validator, changeHandler, submitHandler } = useForm(lang);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [captchaToken, setCaptchaToken] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!executeRecaptcha) {
            console.log("ReCAPTCHA not loaded");
            return;
        }

        try {
            // Exécuter reCAPTCHA
            setIsSubmitting(true);
            const token = await executeRecaptcha('contact_form');
            setCaptchaToken(token);
            if (!token) {
                alert("Please complete the CAPTCHA");
                setIsSubmitting(false); // Réactiver le bouton si CAPTCHA échoue
                return;
            }
            console.log('reCAPTCHA token:', token);
            forms.captchaToken = token;
            // Préparer les données du formulaire avec le token reCAPTCHA
            const formData = {
                ...forms,

            };


            console.log('Form data being sent:', formData);
            // Soumettre le formulaire avec la validation du captcha
            submitHandler(e, formData);
        } catch (error) {
            console.error('ReCAPTCHA error:', error);
            alert("Failed to execute reCAPTCHA. Please try again.");
        } finally {
            setIsSubmitting(false); // Réactiver le bouton après la réponse du serveur
        }
    };
    return (
        // <GoogleReCaptchaProvider
        //     reCaptchaKey='6Le9hjgqAAAAAIiks0H7ZrY300tBth6dc8K5LNgJ' >

            <form method="post" className="contact-validation-active mx-[-15px] overflow-hidden" id="contact-form-main" onSubmit={handleSubmit}>
                <div className="w-[calc(50%-30px)] float-left mx-[15px] mb-[25px] col:float-none col:w-[calc(100%-25px)]">
                    <input
                        className='form-control w-full font-normal bg-[rgba(192,181,150,.2)] h-[50px] border border-[rgba(192,181,150,.5)] text-[#C0B596] transition-all pl-[15px] focus:outline-0 focus:shadow-none focus:border-transparent focus:bg-[rgba(192,181,150,.2)]'
                        value={forms.name}
                        type="text"
                        name="name"
                        // onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        placeholder={t('Your Name')} />
                    {validator.message('name', forms.name, 'required|alpha_space')}
                </div>
                <div className="w-[calc(50%-30px)] float-left mx-[15px] mb-[25px] col:float-none col:w-[calc(100%-25px)]">
                    <input
                        className='form-control  w-full font-normal bg-[rgba(192,181,150,.2)] h-[50px] border border-[rgba(192,181,150,.5)] text-[#C0B596] transition-all pl-[15px] focus:outline-0 focus:shadow-none focus:border-transparent focus:bg-[rgba(192,181,150,.2)]'
                        value={forms.email}
                        type="email"
                        name="email"
                        onChange={(e) => changeHandler(e)}
                        placeholder={t('Your Email')} />
                    {validator.message('email', forms.email, 'required|email')}
                </div>
                <div className="w-[calc(50%-30px)] float-left mx-[15px] mb-[25px] col:float-none col:w-[calc(100%-25px)]">
                    <input
                        className='form-control  w-full font-normal bg-[rgba(192,181,150,.2)] h-[50px] border border-[rgba(192,181,150,.5)] text-[#C0B596] transition-all pl-[15px] focus:outline-0 focus:shadow-none focus:border-transparent focus:bg-[rgba(192,181,150,.2)]'
                        value={forms.phone}
                        type="phone"
                        name="phone"
                        onChange={(e) => changeHandler(e)}
                        placeholder={t('Your Phone')} />
                    {validator.message('phone', forms.phone, 'required|phone')}
                </div>
                <div className="w-[calc(50%-30px)] float-left mx-[15px] mb-[25px] col:float-none col:w-[calc(100%-25px)]">
                    <select
                        className='form-control  w-full font-normal bg-[rgba(192,181,150,.2)] h-[50px] border border-[rgba(192,181,150,.5)] text-[#C0B596] transition-all pl-[15px] focus:outline-0 focus:shadow-none focus:border-transparent focus:bg-[rgba(192,181,150,.2)]'
                        onChange={(e) => changeHandler(e)}
                        value={forms.subject}
                        type="text"
                        name="subject">
                        {catServices.map((cat, item) => (
                            <option key={item} >{cat.name[lang]}</option>
                        ))}
                    </select>
                    {validator.message('subject', forms.subject, 'required')}
                </div>
                <div className="w-[calc-(100%-25px)] mb-[25px] mx-[15px]">
                    <textarea
                        className='form-control  w-full bg-[#0d0d0d33] h-[150px] border border-[rgba(192,181,150,.5)]  text-[#C0B596] transition-all pt-[15px] pl-[15px] focus:outline-0 focus:shadow-none focus:border-transparent focus:bg-[rgba(192,181,150,.2)]'
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder={t('Your Message')}>
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
                <div className="text-left w-full mb-[10px] ml-[16px]">
                    <button
                        type="submit"
                        className={`bg-[#C0B596] text-[16px] font-semibold text-white px-[38px] py-[10px]  capitalize inline-block mt-[6px] transition ease-in-out duration-300 hover:bg-[#d4c291]
                        col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] cursor-pointer ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isSubmitting} // Désactive le bouton si isSubmitting est true
                    >
                        {isSubmitting ? t('Submitting...') : t('button.submit')}
                    </button>

                    {/* <button type="submit" className="bg-[#C0B596] text-[16px] font-semibold text-white px-[38px] py-[10px]  capitalize inline-block mt-[6px] transition ease-in-out duration-300 hover:bg-[#d4c291]
                    col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] cursor-pointer ">{t('button.submit')}</button> */}
                    <div id="loader">
                        <i className="ti-reload"></i>
                    </div>
                </div>
                <div id="submit-message-contact" className='hidden mt-3 text-[#CBB26A] font-bold text-center' > </div>
            </form>
        // </GoogleReCaptchaProvider>
    )
}

export default ConsultingFrom;
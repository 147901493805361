import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const slug = props.slug; 
    const lang = props.lang;
    const id = props.id;
    const catServices = props.catServices;
    const cateroryService = catServices.find(item => item.slug === slug);
    const services = cateroryService.services_active;
    return (
        <div className="col-span-4 md:col-span-6 sm:col-span-8 col:col-span-12 order-2 md:order-3">
            <div className="pl-[40px] lg:pl-0 md:mt-[40px]">      
                <div className="bg-[#f5f5f5] mt-[60px]">
                    <div className="bg-[#ddd] py-[10px] px-[20px] relative  
                        before:z-10 before:absolute before:contnent-[''] before:border-[15px] before:border-transparent 
                        before:border-t-[13px] before:border-t-[rgba(0,0,0,.1)]
                        before:top-full before:left-[30px] ">
                        <h3 className="text-[24px] text-[#282e3f]">
                            <Link
                             onClick={ClickHandler}
                                        to={`/cat-service/${slug}`} 
                                        className={`block transition-all ease-in-out hover:text-[#C0B596]`}>
                                       {cateroryService.name[lang]}
                             </Link>
                            
                        </h3>
                    </div>
                    <div className="pt-[15px] px-[30px] pb-[20px]">
                        <ul>
                        {services.map((service, item) => (
                                <li className="border-b border-[#ddd]" key={item}>
                                    <Link
                                        onClick={ClickHandler}
                                        to={`/service/${slug}/${service.id}`} 
                                        className={`text-[15px] pt-[15px] pb-[10px] 
                                        ${ service.id == id ? 'text-[#C0B596]' : 'text-[#333]'
                                        } block transition-all ease-in-out hover:text-[#C0B596]`}>
                                        {service.name[lang]}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* <div className="mt-[65px] pt-[15px] px-[30px] pb-[20px] bg-[#f5f5f5]">
                    <h3 className="text-[25px] relative text-[#282e3f] text-left capitalize pb-[20px] font-medium
                        ">{t('Services')}</h3>
                    <div className="mt-[10px]">
                        {catServices.map((cat, i) => (
                            <div className="overflow-hidden flex" key={i}>
                                <div className="w-[90px]">
                                    <img className="w-full" src={cat.icon_path} alt="" />
                                </div>
                                <div className="pl-[20px] w-[calc(100%-90px)]">
                                    <h4><Link onClick={ClickHandler} to={`/case-single/${cat.slug}`} className="inline-block font-base-font font-medium
                                     text-[#666] transition-all hover:text-[#C0B596]">Actual {cat.name[lang]} </Link>
                                    </h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}

            </div>
        </div>
    )
};
export default Sidebar;


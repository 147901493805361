import React from 'react';
import ContactForm from '../ContactFrom/ContactForm';
import { useAppContext } from '../../api/AppSettings';
import { useTranslation } from 'react-i18next';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';

const Contactpage = () => {
    const { apiSettings } = useAppContext();
    const { t ,i18n } = useTranslation();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const info = apiSettings.settings;
    const lang = i18n.language;
    const catServices = info.catServices;

    return (

        <section className="relative pt-[60px] md:pt-0 pb-0  z-20">
            <div className="wraper">
                <div className="grid grid-cols-12">
                    <div className="col-span-1"></div>
                    <div className="col-span-10 md:col-span-12">
                        <div className="pb-[50px] sm:pb-[10px]">
                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-4 md:col-span-6 sm:col-span-12 ">
                                    <div className="h-[calc(100%)] text-center lg:p-[40px]  p-[15px] shadow-[0_5px_15px_0_rgba(62,65,159,0.1)]">
                                        <div className="w-[85px] h-[85px] bg-[#f5f5f5] leading-[85px] text-center mx-auto rounded-[50%]">
                                            <div className="icon">
                                                <a target="_blank" href="https://maps.app.goo.gl/FiDtks1ZXbxnhZhh9">   

                                                    <i className="text-[35px] leading-[85px] text-[#282e3f] fa fa-map-marker" aria-hidden="true"></i>
                                                </a>    
                                            </div>
                                        </div>
                                        <h2 className="text-[25px] text-[#282e3f] font-medium my-[20px]">{t('Address')}</h2>

                                        <p className="text-[#666] text-[14px] leading-[22px]">
                                            <a target="_blank" href="https://maps.app.goo.gl/FiDtks1ZXbxnhZhh9">   

                                                {info.full_address}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-span-4 md:col-span-6 sm:col-span-12 md:mb-7">
                                    <div className="h-[calc(100%)] text-center p-[40px]  shadow-[0_5px_15px_0_rgba(62,65,159,0.1)]">
                                        <div className="w-[85px] h-[85px] bg-[#f5f5f5] leading-[85px] text-center mx-auto rounded-[50%]">
                                            <div className="icon">
                                            <a href="#contact-form-main">
                                                <i className="text-[35px] leading-[85px] text-[#282e3f] fa fa-envelope" aria-hidden="true"></i>
                                             </a>     
                                            </div>
                                        </div>
                                        <h2 className="text-[25px] text-[#282e3f] font-medium my-[20px]">{t('Email Us')}</h2>

                                        <p className="text-[#666] text-[16px] leading-[22px]">
                                            <a href="#contact-form-main">
                                            {info.email}
                                           </a>                                             
                                        </p>
                                    
                                    </div>
                                </div>
                                <div className="col-span-4 md:col-span-6 sm:col-span-12 md:mb-7">
                                    <div className="h-[calc(100%)] text-center p-[40px]  shadow-[0_5px_15px_0_rgba(62,65,159,0.1)]">
                                        <div className="w-[85px] h-[85px] bg-[#f5f5f5] leading-[85px] text-center mx-auto rounded-[50%]">
                                        <a  href={`tel:+1${info.phone}`} >
                                            <div className="icon">
                                                <i className="text-[35px] leading-[85px] text-[#282e3f] fa fa-phone" aria-hidden="true"></i>
                                            </div>
                                            </a>
                                        </div>
                                        <h2 className="text-[25px] text-[#282e3f] font-medium my-[20px]">{t('Call Now')}</h2>
                                        <p className="text-[#666] text-[16px] leading-[22px]">
                                        <a  href={`tel:+1${info.phone}`} >
                                            {info.phone_formated}
                                            </a>
                                        </p>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-[440px] mx-auto text-center mb-[50px]">
                            <h2 className="text-[35px] col:text-[25px] text-[#282e3f] font-bold mb-[20px]">  {t('Free Consulting')} </h2>
                            <p className="text-[24px]"> {t('Have Any Question?')}  </p>     
                        </div>
                        <div className="p-[50px] bg-white mb-[-125px] md:mb-[10px] relative z-10 shadow-[0_8px_17px_0_rgba(0,0,0,20%),0_6px_20px_0_rgba(0,0,0,19%)] sm:p-7 sm:pt-[50px]">
                             <ContactForm catServices={catServices} lang={lang}/>
                        </div>
                    </div>
                    <div className="col-span-1"></div>
                </div>
            </div>
            <div className="wpo-contact-map-section ">
                <h2 className="hidden">Contact map</h2>
                <div className="h-[450px] md:h-[250px]">
                <iframe className="w-full h-full border-0 outline-0 grayscale" title='map'
                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.178756819012!2d-73.46776292382155!3d45.466202871073946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x45c3a4b5165a2d9f%3A0x8ccd8e3b26829732!2sYaLisse%20Clinique%20m%C3%A9dico-esth%C3%A9tique!5e0!3m2!1sen!2sca!4v1719102647851!5m2!1sen!2sca" 
                 allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

                </iframe>
                {/* <iframe className="w-full h-full border-0 outline-0 grayscale" title='map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11188.31283710169!2d-73.43222042343079!3d45.456214224178684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1fe01b7b8d4f0b75!2s7055%20Boulevard%20Taschereau%20Bureau%20110%2C%20Brossard%2C%20QC%20J4Z%202K6%2C%20Canada!5e0!3m2!1sen!2sca!4v1647529993861!5m2!1sen!2sca" allowFullScreen></iframe> */}
                </div>
            </div>
        </section>
    )

}

export default Contactpage;

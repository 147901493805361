import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import CategoriesFooter from '../../components/CategoriesFooter';

import Navbar2 from '../../components/Navbar2/Navbar2';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
import { Link } from 'react-router-dom';

const Prices = () => {

    const { apiSettings } = useAppContext();
    const { t, i18n } = useTranslation();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const info = apiSettings.settings;
    const lang = i18n.language;
    const catServices = info.catServices;
    const promotions = info.promotion;

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={t('Our Prices')} pagesub={t('Our Prices')} />

            <div className="py-[30px] md:py-[2px]">
                <div className="wraper">
                    <div className="flex w-full">
                        <div className="flex-row w-full">
                            {catServices.map((catService, Citem) => (
                                <div className="mt-[25px] w-full " key={Citem}>
                                    <div className="mb-8 organigation-text ">
                                        <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">
                                        <Link onClick={ClickHandler} to={`/cat-service/${catService.slug}`} >
                                            {catService.name[lang]}
                                        </Link>                                           
                                        </h2>
                                        {catService.services_active.map((service, item) => {
                                            const promotionItem = promotions.find(itemProm => itemProm.service_id === service.id);

                                            return (
                                                <div key={item} className="lg:flex md:block items-center text-[15px] text-[#333] pb-3 lg:border-0 md:border-b">
                                                    <i className="fa fa-star text-[#C0B596] pr-[15px]" aria-hidden="true"></i>
                                                    <span className="w-2/4">
                                                        <Link onClick={ClickHandler} to={`/service/${catService.slug}/${service.id}`} 
                                                        
                                                        className="transition ease-in-out duration-300 hover:text-[#C0B596]">
                                                        {service.name[lang]} 
                                                    </Link>    
                                                    </span>
                                                    {promotionItem ? (
                                                        <>
                                                            <span className="lg:flex md:block  md:justify-center lg:justify-end ml-10  w-full">
                                                                <s className="text-[12px] line-through text-red-500">{service.price_formated} </s>
                                                                <span className="text-black" >{promotionItem.price_after} $ /{service.unit.name[lang]} </span>
                                                            </span>

                                                        </>
                                                    ) : (
                                                        <span className="lg:flex md:block  md:justify-center lg:justify-end ml-10 w-full">{service.price_formated} /{service.unit.name[lang]} </span>
                                                    )}
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
            </div>
            <ConsultingArea />
            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <CategoriesFooter lang={lang} catServices={catServices} />
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default Prices;

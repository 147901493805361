import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import About2 from '../../components/about2/about2';
import Features from '../../components/Features/Features';
import Newsletter from '../../components/Newsletter/Newsletter';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Navbar2 from '../../components/Navbar2/Navbar2';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import CatServices from '../../components/CatServices';
import { useTranslation } from 'react-i18next';




const AboutPage =() => {
  const { t } = useTranslation(); 
    return(
        <Fragment>
            <Navbar2 hclass={'wpo-header-style-1'} topbarNone={'topbar-none'}  />          
            <PageTitle pageTitle={t('About us')} pagesub={t('About us')} link='#' />
            <Features />
            <About2 />
            <ConsultingArea />
            <CatServices />
            <Newsletter />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;


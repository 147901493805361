import React from 'react';
import ConsultingFrom from '../ConsultingFrom/ConsultingFrom';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
const ConsultingArea = (props) => {
    const { t, i18n } = useTranslation();
    const { apiSettings } = useAppContext();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const info = apiSettings.settings;
    const lang = i18n.language; 
    const catServices = info.catServices;
    const imgBG = info.image_bg_contact_path;


    
    return (
        <section className="bg-[#4b424d] relative bg-center bg-cover bg-no-repeat bg-fixed py-[30px] z-10 
        before:content-[''] before:bg-[#1152830] before:w-full before:h-full before:absolute before:left-0 before:-z-10 before:top-0" 
    style={{ backgroundImage: `url(${imgBG})`, opacity: '0.9' }} >
            <div className="wraper z-20">
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-5 md:col-span-12 ">
                        <div className="contact-text md:mb-[30px]">
                            <div className="title">
                                {/* <span className="text-[16px] text-[#C0B596]">{t ('For Our Honorable Client')}</span> */}
                                <h2 className="text-[36px] md:text-[26px] font-medium text-white pb-[20px] md:pb-[10px] relative 
                        before:absolute before:content-[''] before:left-[-68px] 
                        before:top-[30px] before:transform before:-translate-y-1/2 
                        before:w-[60px] before:h-[1px] before:bg-[#C0B596] md:before:hidden">{t('Free Consulting')}</h2>
                            </div>
                            <span className="text-[16px] text-[#C0B596] text-gradient">{t('Call us at')}   {info.phone_formated}  {t('or fill out the form')}.</span>
                            <p className="mt-[30px] text-[#ddd] leading-[30px] text-[16px]">
                                {t('You can contact us')}</p>
                        </div>
                    </div>
                    <div className="col-span-7 md:col-span-12">
                    <ConsultingFrom catServices={catServices} lang={lang} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConsultingArea;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CategoriesFooter = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const catServices = props.catServices;
    const numberOfServices = catServices.length;
    // console.log('numberOfServices', numberOfServices);
    const lang = props.lang;
    const{ t } = useTranslation();
    return (

        <div className="mt-[10px] order-3 md:order-2">
            <h2 className="text-[36px] md:text-[25px] text-[#282e3f] pb-[20px] mb-[60px] relative 
                before:absolute before:left-0 before:bottom-0 before:content-[''] 
                before:w-[60px] before:h-[2px] before:bg-[#cbbc99]">{t('Services')}</h2>
                
            <div className={`grid grid-cols-12 gap-3`}>
          
                 {catServices.map((cat, item) => (
                    <div className="col-span-4 md:col-span-6 col:col-span-12 " key={item}>
                        <div className="relative group md:mb-[30px]" >
                            <div className="studies-single">
                                <img className="w-full" src={cat.image_path} alt="" />
                            </div>
                            <div className="absolute bottom-[-80px] bg-[#23120Dcc] w-[calc(100%)] h-[calc(100%)] transition ease-in-out duration-500 opacity-0 invisible 
                        group-hover:opacity-100 group-hover:visible group-hover:bottom-0">
                                <div className="absolute left-[5%] top-[5%] w-[90%] h-[90%] text-center border-[2px] border-[#C0B596] z-10 flex justify-center flex-col">
                         
                                    <h3 className="relative text-white text-[22px] pt-[10px] pb-[25px] font-medium 
                             before:absolute before:left-[50%] before:top-full before:content-[''] before:bg-[#C0B596] before:transform before:-translate-x-1/2
                             before:w-[60px] before:h-[3px] before:z-10">
                                        <Link onClick={ClickHandler} to={`/cat-service/${cat.slug}`} 
                                        className="text-white transition ease-in-out duration-300 hover:text-[#C0B596]">
                                            {cat.name[lang]}</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            
        </div>

    )
};
export default CategoriesFooter;


import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const About = (props) => {

    const { t, i18n } = useTranslation();
 
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const info = props.info;
    const lang = i18n.language;

    return (
        <section className="pt-[120px] pb-[100px]">
           <div className="wraper">
               <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-6 md:col-span-12">
                        <div className="relative mb-[20px]">
                            <div className=" relative max-w-[520px] before:absolute before:bg-[#C0B596] before:w-full before:h-full before:content-[''] before:-z-10 before:left-[-20px] before:top-[-20px] ">
                              <img className="w-full" src={info.image_about_path} alt="about us"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 md:col-span-12">
                        <div className="mb-[20px]">
                            <h2 className="text-[#282e3f] text-[32px]">{t ('About us') }</h2>                          
                            <p className="text-[#666] text-[15px] leading-[28px] mb-[30px]">
                                <span dangerouslySetInnerHTML={{ __html: info.about[lang] }}></span>                         
                            </p>                           
                            <div className="mb-[20px] col:mb-[20px]">
                                <Link onClick={ClickHandler} to="/about" className="bg-[#C0B596] cursor-pointer
                                 text-[16px] font-semibold text-white px-[38px] py-[10px]  capitalize inline-block 
                                 mt-[6px] transition ease-in-out duration-300 hover:bg-[#d4c291]
                                col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] 
                                ">
                                   {t('More About Us')} 
                              </Link>
                            </div>
                            <div className="signature ml-5 -mt-5 w-40">
                                <img  src={info.signature_path} alt=""/>
                            </div>
                        </div>
                    </div>
               </div>
           </div>
        </section>
    )
}

export default About;
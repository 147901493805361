import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage/HomePage'
import AboutPage from '../AboutPage/AboutPage';
import CategoryServiceSinglePage from '../CategoryServiceSinglePage/CategoryServiceSinglePage';
import ServiceSinglePage from '../AServiceSinglePage/ServiceSinglePage';
import Promotions from '../Promotions/Promotions';
import Prices from '../Prices/Prices';
import ContactPage from '../ContactPage/ContactPage';
import PrivacyPolicies from '../PrivacyPolicies/PrivacyPolicies';


import { AppProvider } from '../../api/AppSettings';


const AllRoute = () => {


  return (
    <div className="App">
      <BrowserRouter>
        <AppProvider>      
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="home" element={<Homepage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="promotions" element={<Promotions />} />
            <Route path="prices" element={<Prices />} />
            <Route path="cat-service/:slug" element={<CategoryServiceSinglePage />} />
            <Route path="service/:slug/:id" element={<ServiceSinglePage />} />
            <Route path='contact' element={<ContactPage />} />
            <Route path='privacy-policies' element={<PrivacyPolicies />} />
            {/* <Route path='login' element={<LoginPage />} />
            <Route path='register' element={<SignUpPage />} />
            <Route path='forgot-password' element={<ForgotPassword />} /> */}
          </Routes>
         </AppProvider>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
